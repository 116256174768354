import { merge } from 'lodash';
import { filterLeaves } from 'platform-webapp-util';

const formatUrl = (url: string): string => {
  if (url.charAt(url.length - 1) !== '/') {
    return url + '/';
  }
  return url;
};

/** Deployment-specific configuration */
export const config = {
  backendURL: formatUrl(process.env.REACT_APP_BACKEND_URL || `${document.location.origin}/api/`),
  testingMode: process.env.REACT_APP_MIPRO_TESTING_MODE,
  openid: {
    issuer: process.env.REACT_APP_KEYCLOAK_ISSUER ?? '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
  },
} as const;

/**
 * Extend `config` with values from the JSON.
 * Must be called before rendering the application.
 */
export async function applyProductionConfig() {
  if ('production' !== process.env.NODE_ENV && process.env.CI !== 'true') {
    return;
  }

  const response = await fetch('/env/config.json');
  if (!response.ok) {
    console.warn(`Unable to fetch config.json (${response.status} ${response.statusText})`, await response.text());
    return;
  }

  merge(
    config,
    // ignore empty strings to simplify docker-entrypoint.sh
    filterLeaves(await response.json(), (value) => value !== ''),
  );
}

/**
 * Make a single property of an object optional.
 */
export type OptionalProp<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/** Same as the built-in `Partial`, but applies to objects recursively. */
// https://stackoverflow.com/a/51365037
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};
