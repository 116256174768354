import { UserProfile } from 'platform-webapp-frontend';

export type LocalizedString = {
  readonly [language: string]: string;
};

export function resolveLocalizedString(
  s: LocalizedString | string | undefined,
  languages: string | readonly string[],
): string | undefined {
  if (s === undefined) {
    return;
  }
  if (typeof s === 'string') {
    return s;
  }

  let allLanguages: string[] = [];
  if (typeof languages === 'string') {
    allLanguages = [languages];
  } else if (!Array.isArray(languages)) {
    allLanguages = languages.slice();
  }
  allLanguages.push('en');

  for (const lang of allLanguages) {
    if (s[lang]) {
      return s[lang];
    }
  }
}

export function resolvePlaceholders(s: string | undefined, user: UserProfile | undefined): string | undefined {
  if (s === undefined) {
    return;
  }
  if (user === undefined) {
    return s;
  }
  s = s.replaceAll('$TENANT$', user.tenant);
  s = s.replaceAll('$LOCALE$', user.locale ?? '');
  return s;
}
