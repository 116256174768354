import { ErrorAlert, Spinner, useAuthorization, useTranslation } from 'platform-webapp-frontend';
import { AppMetadata, useAppMetadata } from '../apis/apps';
import { resolveLocalizedString, resolvePlaceholders } from '../util/localized-string';
import _ from 'lodash';
import { Button, Card } from 'react-bootstrap';
import { useMemo } from 'react';

function App({ app }: { app: AppMetadata }): JSX.Element {
  const { t, i18n } = useTranslation('landing');
  const authorization = useAuthorization();

  const title = resolveLocalizedString(app.title, i18n.languages);
  const description = resolveLocalizedString(app.description, i18n.languages);

  let url = resolveLocalizedString(app.url, i18n.languages);
  url = resolvePlaceholders(url, authorization.user);

  return (
    <Card className="h-100">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description ?? ''}</Card.Text>
        <Button variant="primary" href={url}>
          {t('open-app')}
        </Button>
      </Card.Body>
    </Card>
  );
}

function AppList({ className, title, apps }: { className?: string; title: string; apps: AppMetadata[] }): JSX.Element {
  const { i18n } = useTranslation('landing');

  const sortedApps = useMemo(() => {
    const sorted = apps.slice();
    sorted.sort((a: AppMetadata, b: AppMetadata) => {
      const aTitle = resolveLocalizedString(a.title, i18n.languages) ?? '';
      const bTitle = resolveLocalizedString(b.title, i18n.languages) ?? '';
      return aTitle.localeCompare(bTitle);
    });
    return sorted;
  }, [i18n, apps]);

  return (
    <>
      <h3 className={className}>{title}</h3>
      <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4 g-4">
        {sortedApps.map((app) => (
          <div className="col">
            <App app={app}></App>
          </div>
        ))}
      </div>
    </>
  );
}

export default function Apps(): JSX.Element {
  const { t } = useTranslation('landing');
  const { data, error, loading } = useAppMetadata();

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorAlert message={t('error-loading')} redirectPath="/"></ErrorAlert>;
  }

  const { false: apps, true: sysadminApps } = _.groupBy(data ?? [], (app) => app.sysadmin);

  return (
    <>
      <AppList title={t('apps')} apps={apps}></AppList>

      {sysadminApps && <AppList className="mt-4" title={t('sysadmin-apps')} apps={sysadminApps}></AppList>}
    </>
  );
}
