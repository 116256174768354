import { roles } from '../types';

export interface TestUserData {
  name?: string;
  email?: string;
  roles?: string[];
  tenant?: string;
  sub?: string;
}

class TestUser {
  #data: TestUserData;

  constructor() {
    const storedData = localStorage.getItem('test-user');
    this.#data = storedData
      ? JSON.parse(storedData)
      : {
          name: 'Test User',
          email: 'test-user@example.com',
          roles: roles,
          tenant: 'mipro',
          sub: 'test-user',
        };
  }

  set(testUser: TestUserData) {
    this.#data = {
      ...this.#data,
      ...testUser,
    };
    localStorage.setItem('test-user', JSON.stringify(this.#data));
  }

  get() {
    return JSON.stringify({
      name: this.#data.name,
      email: this.#data.email,
      tenant: this.#data.tenant,
      sub: this.#data.sub,
      roles: {
        '': this.#data.roles,
        'mipro-landing': this.#data.roles,
      },
    });
  }
}

const testUser = new TestUser();
export default testUser;
