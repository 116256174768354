import { useApi } from 'platform-webapp-frontend';
import { LocalizedString } from '../util/localized-string';

export interface AppMetadata {
  title: LocalizedString;
  description?: LocalizedString;
  url: string;
  sysadmin?: boolean;
}

export function useAppMetadata() {
  return useApi<AppMetadata[]>(['apps'], {}, {}, []);
}
