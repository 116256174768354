import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { i18nInit, initializeApi } from 'platform-webapp-frontend';
import { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import App from './common/App';
import { applyProductionConfig, config } from './common/util';
import './scss/global.scss';
import testUser from './util/test-user';

//import reportWebVitals from './reportWebVitals';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// keep existing placeholder content during React load
const rootElement = document.getElementById('root') as HTMLElement;
const fallback = (
  <div
    dangerouslySetInnerHTML={{
      __html: rootElement?.innerHTML ?? 'Loading&hellip;',
    }}
  />
);

i18nInit(
  (language: string, namespace: string) => {
    return import(`./locales/${language}/${namespace}.json`);
  },
  {
    ns: ['common'],
    defaultNS: 'common',
  },
);

initializeApi(config.backendURL);

window.setTestUser = config.testingMode
  ? (user) => {
      console.warn('Setting test user', user);
      testUser.set(user);
    }
  : undefined;

function doRender() {
  render(
    <Suspense fallback={fallback}>
      <StrictMode>
        <App></App>
      </StrictMode>
    </Suspense>,
    rootElement,
  );
}

applyProductionConfig().then(doRender);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
