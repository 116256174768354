"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApiDownload = exports.useApi = exports.getApiUrl = exports.isApiUrl = exports.initializeApi = exports.config = void 0;
const use_http_1 = __importStar(require("use-http"));
const react_1 = require("react");
exports.config = {
    backendURL: undefined,
};
function initializeApi(url) {
    exports.config.backendURL = url;
}
exports.initializeApi = initializeApi;
function isApiUrl(url) {
    try {
        if (!exports.config.backendURL) {
            throw new Error('initializeApi(url: string) must be called before platform-webapp API functionality can be used!');
        }
        const requestUrl = new URL(url, document.location.href);
        const backendUrl = new URL(exports.config.backendURL, document.location.href);
        return requestUrl.href.startsWith(backendUrl.href);
    }
    catch (err) {
        console.error('isApiUrl', err);
        return false;
    }
}
exports.isApiUrl = isApiUrl;
function getApiUrl(pathComponents = [], queryParameters = {}) {
    if (!exports.config.backendURL) {
        throw new Error('initializeApi(url: string) must be called before platform-webapp API functionality can be used!');
    }
    const url = new URL(pathComponents.map((component) => encodeURIComponent(component)).join('/'), exports.config.backendURL);
    for (const key of Object.keys(queryParameters)) {
        if (queryParameters[key]) {
            url.searchParams.set(key, queryParameters[key]);
        }
    }
    return url;
}
exports.getApiUrl = getApiUrl;
/**
 * Calls an API endpoint
 * @param pathComponents Request path, as an array of the path's components (automatically escaped)
 */
function useApi(pathComponents, queryParameters, options, dependencies) {
    const url = getApiUrl(pathComponents, queryParameters).toString();
    if (dependencies !== undefined) {
        dependencies = [...dependencies, url];
    }
    return (0, use_http_1.default)(url, {
        cachePolicy: use_http_1.CachePolicies.NO_CACHE,
        ...options,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...options.headers,
        },
    }, dependencies);
}
exports.useApi = useApi;
/**
 * Returns a function that calls an API endpoint, then downloads the response as a file.
 * @param pathComponents Request path, as an array of the path's components (automatically escaped)
 */
function useApiDownload(pathComponents, queryParameters, options, dependencies) {
    const { get } = useApi(pathComponents, queryParameters, {
        ...options,
        responseType: 'blob',
        headers: {
            ...options.headers,
            Accept: '*',
        },
    }, dependencies);
    const download = (0, react_1.useCallback)(async (filename, extraUrlSuffix) => {
        const response = await get(extraUrlSuffix);
        const url = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        // The timeout doesn't seem to be strictly necessary,
        // but just in case a browser extension messes with the click event or something...
        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }, 1000);
    }, [get]);
    return download;
}
exports.useApiDownload = useApiDownload;
