"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapLeaves = exports.filterLeaves = exports.slow = exports.one = exports.asyncFilter = exports.isNotNullish = exports.retainProperties = exports.assertDefined = exports.unreachable = void 0;
/**
 * Useful for checking exhaustiveness of `switch` statements at compile time.
 * @throws always
 */
function unreachable(value) {
    throw new Error(`Unreachable reached: ${value}`);
}
exports.unreachable = unreachable;
/**
 * @throws if the given value is `undefined`
 * @returns the given value, with `undefined` removed from its type
 */
function assertDefined(value, msg) {
    if (value === undefined) {
        throw new Error(msg ?? 'Assertion error');
    }
    return value;
}
exports.assertDefined = assertDefined;
/**
 * Useful in combination with `keys` from `ts-transformer-keys`.
 * @returns A shallow copy of the object, only retaining properties named in `props`.
 */
function retainProperties(obj, props) {
    const output = Object.create(Object.getPrototypeOf(obj));
    for (const key of props) {
        const value = Object.getOwnPropertyDescriptor(obj, key);
        if (value !== undefined) {
            Object.defineProperty(output, key, value);
        }
    }
    return output;
}
exports.retainProperties = retainProperties;
/**
 * @returns `false` if `value` is `null` or `undefined`, otherwise `true`
 * Needed for proper type inference in some cases, such as RxJS `filter`.
 */
function isNotNullish(value) {
    return value !== null && value !== undefined;
}
exports.isNotNullish = isNotNullish;
/**
 * `Array.prototype.filter` except the predicate can be async.
 * @link https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
 */
async function asyncFilter(array, predicate, thisArg) {
    const results = await Promise.all(array.map(predicate, thisArg));
    return array.filter((value, index) => results[index]);
}
exports.asyncFilter = asyncFilter;
/**
 * @param value - An array of length 1, or a non-array value
 * @returns The first element of the array, or if given a non-array the value itself
 * @throws If given an array with length > 1
 */
function one(value) {
    if (Array.isArray(value)) {
        if (value.length > 1) {
            throw new Error(`one() given an array of length ${value.length}`);
        }
        return value[0];
    }
    return value;
}
exports.one = one;
/**
 * Resolves with its input value, after a delay (in ms).
 * Useful for testing loading behaviors involving async operations.
 */
function slow(value, delay = 1000) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value);
        }, delay);
    });
}
exports.slow = slow;
/**
 * Clones the object, leaving only properties for which `cond` returns true.
 * Recurses into objects, only ever passing leaves to `cond`. Doesn't recurse into arrays.
 */
function filterLeaves(obj, cond) {
    const out = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            out[key] = filterLeaves(value, cond);
        }
        else if (typeof key !== 'string' || cond(value, key)) {
            out[key] = value;
        }
    }
    return out;
}
exports.filterLeaves = filterLeaves;
/**
 * Maps the values of all leaves of the given object using the given function.
 * Recurses into objects, only ever passing leaves to `fn`. Doesn't recurse into arrays.
 */
function mapLeaves(obj, fn) {
    const out = obj;
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            out[key] = mapLeaves(value, fn);
        }
        else {
            out[key] = fn(value, key);
        }
    }
    return out;
}
exports.mapLeaves = mapLeaves;
