import { NavigationBar, PlatformWebapp } from 'platform-webapp-frontend';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'platform-webapp-frontend';
import FallbackComponent from './FallbackComponent';
import { Role, RolePrefix } from '../types';
import { IncomingOptions } from 'use-http';
import { config } from './util';
import testUser from '../util/test-user';
import classes from './App.module.scss';
import Apps from '../landing/Apps';

// Only used for testing mode
const httpOptions: IncomingOptions = {
  interceptors: {
    request: async ({ options }) => {
      return {
        ...options,
        headers: {
          ...options.headers,
          'Test-User': testUser.get(),
        },
      };
    },
  },
};

/**
 * Root component for the entire application.
 * Rendered after the user's SSO session has been established.
 */
export default function App(): JSX.Element {
  const { t, i18n } = useTranslation('common');

  // update document level localizable resources when the language changes
  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language);

    let link = document.head.querySelector('link[rel="manifest"]');
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'manifest');
      document.head.append(link);
    }
    link.setAttribute('href', `/manifest.${i18n.language}.json`);

    document.title = t('title');
  }, [i18n.language, t]);

  return (
    <div className="mb-5">
      <PlatformWebapp<Role, RolePrefix>
        header={<NavigationBar title={t('title')}></NavigationBar>}
        httpOptions={config.testingMode ? httpOptions : undefined}
        openid={config.openid}
      >
        <div className={classes['wrapper']}>
          <div className={classes['wrapper-box']}>
            <ErrorBoundary FallbackComponent={FallbackComponent}>
              <div className={classes['content']}>
                <Apps />
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </PlatformWebapp>
    </div>
  );
}
