import { Alert, Button, Container } from 'react-bootstrap';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'platform-webapp-frontend';

/**
 * Generic FallbackComponent for ErrorBoundary. Shows the error message and a reset button.
 */
export default function FallbackComponent({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  const { t } = useTranslation('common');

  console.error(error);
  return (
    <>
      <Container data-testid="main-container" className="pt-2">
        <Alert variant="danger">
          <p>{t('fallback.title')}</p>
          <pre>{error.message}</pre>
          <Button
            variant="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('fallback.reset')}
          </Button>
        </Alert>
      </Container>
    </>
  );
}
